<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="6" outlined>
      <v-card-title>
                <span class="headline">
                    {{ this.$t('erp.lang_editTemplate') }}
                </span>
        <v-spacer/>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="avtivateAll" :loading="avtivateAll" @click="(avtivateAll = true) & changeState('active')" color="success" icon v-on="on">
              <v-icon>check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_activateAll') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="disavtivateAll" :loading="disavtivateAll" @click="(disavtivateAll = true) & changeState('inactive')" color="error" icon v-on="on">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_disableAll') }}</span>
        </v-tooltip>

      </v-card-title>

      <v-divider class="ma-0 pa-0"/>
      <v-card-text class="ma-0 pa-0">
        <v-card elevation="0">
          <v-card-title class="pa-0 ma-0">
            <v-row>
              <v-col class="pt-0 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <b-input-group width="100%">
                      <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :placeholder="$t('datatables.lang_search')"
                                    @focus="showTouchKeyboard"
                                    v-model="search"/>
                    </b-input-group>
                  </div>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                            <span slot="button-content"><i
                                                class="pe-7s-menu btn-icon-wrapper"></i></span>
                      <div>
                        <h6 class="dropdown-header" tabindex="-1">
                          {{ this.$t('generic.lang_Options') }}</h6>
                        <button @click="generatePDF" class="dropdown-item" tabindex="0"
                                type="button"
                        >
                          <v-icon class="dropdown-icon" color="warning">picture_as_pdf
                          </v-icon>
                          <span>PDF</span></button>

                        <!-- Excel Download: ONLY SELECTED ITEMS -->
                        <vue-excel-xlsx
                            :columns="excelColumns"
                            :data="selectedRows"
                            :filename="excelFileName"
                            :sheetname="'Sheet1'"
                            v-if="selectedRows.length > 0 && !noExcelExport"
                        >
                          <button class="dropdown-item" tabindex="0"
                                  type="button"
                                  v-if="selectedRows.length > 0">
                            <font-awesome-icon class="dropdown-icon success--text"
                                               icon="file-excel"/>
                            <span>Excel</span></button>

                        </vue-excel-xlsx>
                        <vue-excel-xlsx
                            :columns="excelColumns"
                            :data="filteredDatatableEntries"
                            :filename="excelFileName"
                            :sheetname="'Sheet1'"
                            v-if="selectedRows.length === 0"
                        >
                          <button class="dropdown-item" tabindex="0" type="button"
                                  v-if="selectedRows.length === 0 ">
                            <font-awesome-icon class="dropdown-icon success--text"
                                               icon="file-excel"/>
                            <span>Excel</span></button>
                        </vue-excel-xlsx>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <!-- MULTI SEARCH -->
          <v-container>
            <v-row>
              <v-col
                style="margin-top: -25px"
                v-for="header in filteredSearchableHeaders"
                :key="header.value"
                cols="12"
                :md="header.searchCol"
              >
                <v-text-field
                  v-if="header.searchType === 'text'"
                  @input="(text) => multiSearchColumn(text, header)"
                  :value="getMultiSearchColumnValue(header)"
                  @focus="showTouchKeyboard"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :label="header.text"
                  dense
                  height="20"
                  outlined
                  style="margin-top: 15px; margin-bottom: -15px"
                ></v-text-field>
                <v-select
                  v-if="
                    header.searchType === 'multiselect' ||
                    header.searchType === 'singleselect'
                  "
                  dense
                  @input="(text) => multiSearchColumn(text, header)"
                  :value="getMultiSearchColumnValue(header)"
                  :items="searchPayload[header.value]"
                  item-text="name"
                  item-value="id"
                  :label="header.text"
                  :multiple="header.searchType === 'multiselect'"
                  chips
                  deletable-chips
                  outlined
                  style="margin-top: 15px; margin-bottom: -15px"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>

          <v-data-table
              :footer-props="footerProps"
              :headers="filteredDatatableHeaders"
              :items="filteredDatatableEntries"
              :loading="loading"
              :options.sync="pagination"
              :server-items-length="total"
              :show-select="true"
              footer-props.items-per-page-text="Einträge pro Seite"
              v-model="selectedRows"
          >

            <template v-slot:item.old_price="{ item }">

              <span>{{ item.old_price | currency }}</span>

            </template>

            <template v-slot:item.total="{ item }">
              <b-input-group :append="$store.getters['settings/currencyData']('symbol')">
                <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" @blur="update(item , 5)"
                              @keypress.enter="update(item , 5)"
                              v-model="item.total"/>
              </b-input-group>

            </template>

            <template v-slot:item.status="{ item }">
              <v-row align="center" justify="center">
                <v-switch @change="update(item , 6)"
                          v-model="item.status"/>
              </v-row>
            </template>
          </v-data-table>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                              :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>


        <!-- html2pdf -->
        <div :v-show="false">
          <vue-html2pdf

              :paginate-elements-by-height="1400"
              :pdf-quality="2"
              :preview-modal="true"
              :show-layout="false"
              filename="PricetemplatesItems"
              pdf-format="a4"
              pdf-orientation="portrait"
              ref="html2Pdf"
          >
            <section id="pdfcontainer"
                     slot="pdf-content">
              <section class="pdf-item">
                <h4 id="pdf-title">
                  {{ $t('erp.lang_priceTemplateItems') }} ( {{ this.$route.params.id }} )
                </h4>

                <v-container>

                  <v-simple-table id="pdftable">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th :key="i" v-for="(head , i) in excelColumns">
                          {{ head.label }}
                        </th>
                      </tr>
                      </thead>
                      <tbody v-if="selectedRows.length > 0">
                      <tr :key="i" v-for="(elt , i) in selectedRows">
                        <td :key="j" v-for="(row , j) in elt">{{
                            row === true || row === false ?
                                row ? $t('erp.lang_settings_activ') :
                                    $t('erp.lang_settings_deactiviert') : row
                          }}
                        </td>
                      </tr>
                      </tbody>

                      <tbody v-else>
                      <tr :key="i" v-for="(elt , i) in dataToShow">
                        <td :key="j" v-for="(row , j) in elt">{{
                            row === true || row === false ?
                                row ? $t('erp.lang_settings_activ') :
                                    $t('erp.lang_settings_deactiviert') : row
                          }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </section>

            </section>
          </vue-html2pdf>
        </div>


      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {faBarcodeRead} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Events} from "../../../../plugins/events";
import {ENDPOINTS} from "../../../../config";
import mixin from "../../../../mixins/KeyboardMixIns";

import VueHtml2pdf from 'vue-html2pdf'

library.add(
    faFileExcel, faBarcodeRead
);

export default {
  name: "Datatable",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      excelFileName: "Price template table",
      dataToShow: [],
      search: '',
      awaitingSearch: null,
      total: 0,
      loading: false,
      selectedRows: [],
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      avtivateAll: false,
      disavtivateAll: false,
      itemGroups: [],
      suppliers: [],
      producers: [],
      itemname: "",
      item_id: "",
      selectedItemGroups: [],
      selectedSupplier: "",
      selectedProducer: "",
      searchPayload: {},
      multiSearchColumns: {},
      lockWatch: true,
      resetSearch: false,
      awaitingMultiSearch: null,
    }
  },

  computed: {
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('erp.lang_ware_create_id'),
          field: "item_id",
        },
        {
          label: this.$t('erp.lang_posItemName'),
          field: "itemname",
        },
        {
          label: this.$t('erp.lang_ware_brut'),
          field: "total",
        },
        {
          label: this.$t('generic.lang_status'),
          field: "status",
        },
      ];
    },
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          sortable: true,
          hide: true
        },
        {
          text: this.$t('erp.lang_ware_create_id'),
          value: "item_id",
          searchable: true,
          searchCol: 6
        },
        {
          text: this.$t('erp.lang_posItemName'),
          value: "itemname",
          sortable: false,
          searchable: true,
          searchCol: 6
        },
        {
          text: this.$t('generic.lang_waregroup'),
          value: "ware_cat",
          sortable: false
        },
        {
          text: this.$t('generic.lang_originalPrice'),
          value: "old_price",
        },
        {
          text: this.$t('erp.lang_define_price'),
          value: "total",
          sortable: false
        },
        {
          text: this.$t('generic.lang_status'),
          value: "status",
          align: "center",
        },
      ];
    },
    datatableAttachSearch: function () {
      return [
        {text: this.$t('generic.lang_waregroup'), value: 'itemGroup', searchType: 'multiselect', searchCol: 4},
        {text: this.$t('erp.lang_nav_supplier'), value: 'supplier_id', searchType: 'singleselect', searchCol: 4},
        {text: this.$t('erp.lang_nav_manufacturer'), value: 'producer_id', searchType: 'singleselect', searchCol: 4}
      ];
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }

        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};
        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];
          }
        });
        dataToRender.push(row);
      });

      return dataToRender;
    },
    filteredSearchableHeaders() {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("searchable"))
          if (header.searchable) {
            if (!header.hasOwnProperty("searchType"))
              header.searchType = "text";

            header.attached = false;

            headers.push(header);
          }
      });

      //ATTACH ATTACHED-SEARCH HEADERS
      this.datatableAttachSearch.forEach((search) => {
        search.attached = true;

        headers.push(search);
      });

      return headers;
    },
  },

  created() {
    //LOCK WATCHER
    this.lockWatch = true;

    //GET SAVED STATE
    let savedState = this.$store.getters["datatable/getSavedState"](
        this.$route.name
    );

    if (savedState !== null) {
      this.multiSearchColumns = savedState.multiSearchColumns;
      this.pagination = savedState.pagination;
      if (!this.resetSearch) {
        this.search = savedState.search;
      }


      this.$nextTick(() => {
        this.lockWatch = false;
      });
    } else {
      this.lockWatch = false;
    }
  },
  mounted() {
    this.getDataFromApi();
    this.getSuppliers();
    this.getProducers();
    this.getItemGroups();
  },
  watch: {
    search: function () {
      if (this.lockWatch) return;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 1000);
    },
    pagination: {
      handler() {
        if (this.lockWatch) return;

        this.getDataFromApi();
      },
      deep: true,
    },
    multiSearchColumns: {
      handler() {
        if (this.lockWatch) return;

        clearTimeout(this.awaitingMultiSearch);

        this.awaitingMultiSearch = setTimeout(() => {
          this.getDataFromApi();
        }, 1000);
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    }
  },

  methods: {
    async getItemGroups() {
      let data = {};

      //ITEMGROUPS
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      data = itemgroups.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.searchPayload["itemGroup"] = data;
    },

    getSuppliers() {
      return this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS")
          var data = {};

          //SUPPLIERS
          data = res.data.suppliers
            .map((supplier) => {
              return {
                id: supplier.id,
                name: supplier.supplier_name,
              };
            })
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            this.searchPayload["supplier_id"] = data;
      });
    },
    getProducers() {
      return this.axios.post(ENDPOINTS.ERP.PRODUCERS.GETALL).then((res) => {
        if (res.data.success)
        var data = {};

        //PRODUCERS
        data = res.data.producers
          .map((producer) => {
            return {
              id: producer.id,
              name: producer.producer_name,
            };
          })
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          this.searchPayload["producer_id"] = data;
      });
    },
    update(item, column) {
      this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.EDITITEM, {
        priceTMPLid: this.$route.params.id,
        value: column == 6 ? item.status ? 'Yes' : 'No' : item.total,
        row_id: item.id,
        column: column,
        id: item.id
      }).then((res) => {
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('generic.lang_ActionSuccessful')
        });
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.avtivateAll = false;
        this.disavtivateAll = false;
      });
    },
    changeState(state) {
      this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.PRICETMPLDATASTATE, {
        priceTemplateID: this.$route.params.id,
        state: state
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });
          this.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.avtivateAll = false;
        this.disavtivateAll = false;
      });
    },
    multiSearchColumn(input, header) {
      this.$set(this.multiSearchColumns, header.value, input);
    },
    getMultiSearchColumnValue(header) {
      if (!this.multiSearchColumns.hasOwnProperty(header.value)) return "";

      return this.multiSearchColumns[header.value];
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }

      //MULTI SEARCH
      for (let headerValue in this.multiSearchColumns) {
        if (!this.multiSearchColumns.hasOwnProperty(headerValue)) continue;

        let multiSearch = this.filteredSearchableHeaders.find(
            (header) => header.value === headerValue
        );
        let tableIndex = this.datatableHeaders.findIndex(
            (header) => header.value === headerValue
        );

        if (multiSearch?.attached) {
          params["attached_" + headerValue] = this.multiSearchColumns[headerValue];
        } else {
          params["bSearchable_" + tableIndex] = "true";
          params["sSearch_" + tableIndex] = this.multiSearchColumns[headerValue];
        }
      }

      params.priceTMPLid = this.$route.params.id;

      this.axios.get(ENDPOINTS.DATATABLES.ERP.PRICETEMPLATES.ITEMS, {
        params: params
      }).then((res) => {
        this.total = parseInt(res.data.iTotalDisplayRecords);
        this.dataToShow = res.data.aaData;

        //SAVE STATE
        this.$store.dispatch("datatable/saveState", {
          routeName: this.$route.name,
          state: {
            pagination: this.pagination,
            multiSearchColumns: this.multiSearchColumns,
            search: this.search,
          },
        });

        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    }
  }
}
</script>

<style scoped>
.min-width {
  min-width: 300px !important;
}

#pdf-title {
  background-color: grey !important;
  text-align: center !important;
  height: 40px !important;
  padding: 5px !important;
  color: white;
  background-color: #1e3c72;
}

#pdftable {
  width: 100% !important;

}

#pdfcontainer {
  width: 102% !important;
  margin-left: -12px !important;
  padding: 5px !important;
}

#pdfcontainer_land {
  width: 142.5% !important;
  margin-left: -12px !important;
  padding: 5px !important;
}

@media (max-width: 767.98px) {
  .card-header.card-header-tab .card-header-title {
    width: 100%;
  }
}

</style>