<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_priceTemplates') "
                :icon="icon"
                :subheading="$t('erp.lang_priceTemplates')"
                show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <price-template-edit-compnent />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import PriceTemplateEditCompnent from "../../../../components/erp/baseData/pricetemplate/PriceTemplateEditCompnent";

    export default {
        components: {
            PriceTemplateEditCompnent,
            PageTitle,

        },
        data: () => ({
            icon: "euro_symbol icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>